import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Company = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="会社概要｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-companyPage bg-no-repeat bg-center bg-cover z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">Company</h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <AnchorLink to="#gaiyou" className=''>
                会社概要
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#enkaku" className=''>
                沿革
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#map" className=''>
                アクセスマップ
              </AnchorLink>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

          <section id="PageDescription" className="flex flex-wrap items-center w-full mb-10">
            <StaticImage src="../images/CompanyLogo.png" className="w-24 sm:w-1/6 my-5 mx-auto"/>
            <p className="w-full sm:w-5/6 sm:pl-10 pt-5 text-xl lg:text-2xl leading-relaxed sm:leading-relaxed">
            株式会社スタートアッププラスは<br className="hidden sm:block"/>「WEBシステムの受託開発」と「自社サービスの提供」<br className="hidden sm:block"/>を主な事業として展開しています。
            </p>
            
          </section>

          <section id="gaiyou" className="mb-20">
            <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              会社概要
            </h2>
            <table className="w-full md:table-fixed text-sm md:text-base">
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">会社名</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                  株式会社スタートアッププラス<br />
                  Startup Plus, Inc.
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">所在地</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                〒107-0052<br />
                東京都港区赤坂6-4-19　赤坂T.S.C.ビル5階<br />
                5F Akasaka T.S.C. Building, 6-4-19 Akasaka, Minato-ku, Tokyo
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">設立</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                2012年2月29日
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">資本金</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                1000万円
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">役員</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                代表取締役　桒山　雄介<br />
                取締役　廣田　徹也
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">事業内容</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                コンピュータのシステム開発、及び、運用・保守サポート業務<br />同業務に関連するコンサルタント業務
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">連絡先</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                info＠startup-plus.com （＠を半角に変更して送信してください）
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">URL</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                https://www.startup-plus.com/
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-2 md:px-10 text-center">主要取引先</td>
                <td className=" w-5/6 px-2 md:px-10 py-5 border-l-[5px] border-key-sand">
                株式会社マクロミル<br />
                公益財団法人日本財団<br />
                メディカル・データ・ビジョン株式会社<br />
                株式会社アスマーク<br />
                株式会社AB&Company<br />
                株式会社エスアイイー<br />
                株式会社デンソー
                </td>
              </tr>
            </table>
          </section>


          <section id="enkaku" className="mb-20">
            <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              沿革
            </h2>
            <table className="w-full md:table-fixed text-sm md:text-base">
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] pt-[14px] sm:pt-[22px] rounded-full bg-key-sand text-base sm:text-xl text-white">
                    2012
                  </p>
                </td>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  2月29日
                </td>
                <td className=" w-5/6 px-1 md:px-5 py-5 ">
                株式会社スタートアッププラスを設立
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[2px] h-[100px] -my-14 ml-[24px] sm:ml-[34px] bg-key-sand"></p>
                </td>
                <td className=" w-1/6 px-1 md:px-10 text-center">
                  7月2日
                </td>
                <td className=" w-5/6 px-1 md:px-5 py-5 ">
                東京都渋谷区千駄ヶ谷4丁目に移転
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] pt-[14px] sm:pt-[22px] rounded-full bg-key-sand text-base sm:text-xl text-white">
                    2014
                  </p>
                </td>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  4月2日
                </td>
                <td className=" w-5/6 px-1 md:px-5 py-5 ">
                東京都港区六本木6丁目に移転
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[2px] h-[30px] -my-2 ml-[24px] sm:ml-[34px] bg-key-sand"></p>
                </td>
                <td className=" w-1/6">
                </td>
                <td className=" w-5/6  py-1">
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] pt-[14px] sm:pt-[22px] rounded-full bg-key-sand text-base sm:text-xl text-white">
                    2016
                  </p>
                </td>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  10月4日
                </td>
                <td className=" w-5/6 px-1 md:px-5 py-5 ">
                資本金を1000万円に増資
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[2px] h-[30px] -my-2 ml-[24px] sm:ml-[34px] bg-key-sand"></p>
                </td>
                <td className=" w-1/6">
                </td>
                <td className=" w-5/6  py-1">
                </td>
              </tr>
              <tr>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  <p className="w-[50px] sm:w-[70px] h-[50px] sm:h-[70px] pt-[14px] sm:pt-[22px] rounded-full bg-key-sand text-base sm:text-xl text-white">
                    2021
                  </p>
                </td>
                <td className=" w-1/6 px-1 md:px-5 text-center">
                  4月30日
                </td>
                <td className=" w-5/6 px-1 md:px-5 py-5 ">
                東京都港区赤坂6丁目に移転
                </td>
              </tr>
            </table>
          
          </section>


          <section id="map" className="mb-20">
            <h2 className="text-2xl border-b border-key-sand my-5 py-3 ">
              アクセスマップ
            </h2>
            <div className="md:flex">
              <div className=" max-w-xl ">
                <p className="m-3">
                〒107-0052<br />
                東京都港区赤坂6-4-19　赤坂T.S.C.ビル5階
                </p>
                <Link to="https://goo.gl/maps/wQERfurRphGHA1Fd8" target="_blank" rel="nofollow" 
                  className="m-3 p-2 border border-key-sand ">
                  <svg className="w-6 h-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" /></svg>
                  <span className="ml-1">GoogleMapを開く</span>
                </Link>
                <StaticImage src="../images/map.png" className=" m-5"/>
              </div>
              <div className=" max-w-xl ">
                <p className="m-3">
                赤坂駅　徒歩3分　※最寄り駅<br />
                赤坂見附駅　徒歩10分　／　溜池山王　徒歩10分
                </p>
                <StaticImage src="../images/map3D.png" className=" m-5"/>
              </div>
            </div>
          </section>

          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Company

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
